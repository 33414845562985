import { IAssetsAPI } from './assets.impl'
import { OrdersAPI } from '../../interfaces/database/orders.api.interface'
import { MutateContactData } from '../../graphql/app/MutateContactData'
import { MutateOrder } from '../../graphql/app/MutateOrder'
import { MutateInvoicingData } from '../../graphql/app/MutateInvoicingData'
import { GQLConfiguration } from '../../graphql/app/GQLConfiguration'
import { GQLOrder } from '../../graphql/app/order/GQLOrder'
import { ContactData } from '@/models/order/ContactData'
import { GQLNamespace } from '@/api/graphql/schema/generic.types'
import { InvoicingData } from '@/models/order/InvoicingData'
import { Configuration } from '@/models/order/Configuration'
import { Order, OrderExport } from '@/models/order/Order'
import { ulog, clog } from '@/common/utils/AppConsole'
import { GQLAssetFolder } from '../../graphql/app/GQLAssetFolder'
import { PimcoreNode } from '@/models/pimcore/PimcoreNode'
import { useCreateOfferWithNumber } from '@/api/graphql/app/order/createOfferWithNumber'
import { Maybe } from '@/common/_types'

type CreateIDs = {
  orderFullPath: string
  order: number
  invoicing: number
  contact: number
  configurations: number[]
}

export const IOrdersAPI: OrdersAPI = {

  read: fullpath => GQLOrder.Get.execute({ fullpath }),

  create: async (orderData) => {
    const createdEntities: string[] = []
    const ID: CreateIDs = {
      orderFullPath: '',
      order: 0,
      invoicing: 0,
      contact: 0,
      configurations: [],
    }

    const configuratorVersion = orderData.configuratorVersion || '2'
    const { execute: executeCreateOfferWithNumber } = useCreateOfferWithNumber()

    let createdOrder: Maybe<Order> = null

    // (1.) Empty order object
    if (orderData.orderType === 'offer') {
      createdOrder = await executeCreateOfferWithNumber(0, { configuratorVersion }).then((result) => result?.data)
    }
    else if (orderData.orderType === 'order') {
      createdOrder = await MutateOrder.Create.procedure({ configuratorVersion })
    }

    if (!createdOrder) {
      return createOrderRevert(createdEntities)
    }

    ID.order = parseInt(createdOrder.id!)
    ID.orderFullPath = createdOrder.fullpath!

    // Generator
    const PerformCreation = async <T extends PimcoreNode>(GQL: GQLNamespace<T>, data: T, confKey?: string) => {
      const localEntityKey = confKey || GQL.Create.keyGen(createdOrder!.orderNumber)
      const result = await GQL.Create.procedure(localEntityKey, ID.order, data)
      if (result?.id) createdEntities.push(localEntityKey)
      return result
    }

    // (3.) Invoicing
    const createdInvoicingData = await PerformCreation<InvoicingData>(MutateInvoicingData, orderData.invoicingData)
    if (!createdInvoicingData) return createOrderRevert(createdEntities)
    ID.invoicing = parseInt(createdInvoicingData.id!)

    // (4.) Contact
    const createdContactData = await PerformCreation<ContactData>(MutateContactData, orderData.contactData)
    if (!createdContactData) return createOrderRevert(createdEntities)
    ID.contact = parseInt(createdContactData.id!)

    // (5. + 6. * N) Configurations
    const numberOfNewConfigurations = orderData.configurations.length
    for (let i = 0; i < numberOfNewConfigurations; i++) {
      const confItem = orderData.configurations[i]
      const confItemeGeneratedKey = GQLConfiguration.Create.keyGen(createdOrder.orderNumber)
      confItem.attachmentFolderId = await IAssetsAPI.createFolder(createdOrder.assetFolderId, confItemeGeneratedKey)

      const createdConf = await PerformCreation<Configuration>(GQLConfiguration, confItem, confItemeGeneratedKey)
      if (!createdConf) return createOrderRevert(createdEntities)
      ID.configurations.push(parseInt(createdConf.id!))
    }

    return await createOrderFinish(ID)
  },

  update: async (order, metadata) => {
    // clog((order.key && order.fullpath), 'OrdersAPIProdImpl::update', 'order key not fullpath should not be null', order)

    await MutateInvoicingData.Update.procedure(order.invoicingData)
    await MutateContactData.Update.procedure(order.contactData)

    const ConfigurationIDs: number[] = []
    for (let i = 0; i < order.configurations.length; i++) {
      const conf = order.configurations[i]
      if (!conf.id) {
        // this configuration does not exist yet
        const generatedKey = GQLConfiguration.Create.keyGen(order.key!)

        // Create configuration attachment folder within the order asset folder
        conf.attachmentFolderId = await IAssetsAPI.createFolder(order.assetFolderId, generatedKey)

        // only return UpdateOrderDoorConfigurationInput
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { selectedConfiguration, selectedConfigurationApp, selectedConfigurationWeb, selectedPrice, ...apiConf } = conf

        const createConfigurationResult = await GQLConfiguration.Create.procedure(generatedKey, parseInt(order.id!), apiConf)
        if (createConfigurationResult) {
          ConfigurationIDs.push(parseInt(createConfigurationResult.id!))
        }
      }
      else {
        ConfigurationIDs.push(parseInt(conf.id!))

        // Add base configuration asset folder if nonexistent
        if (!conf.attachmentFolderId) {
          conf.attachmentFolderId = await IAssetsAPI.createFolder(order.assetFolderId, conf.key!)
        }

        // only return UpdateOrderDoorConfigurationInput
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { selectedConfiguration, selectedConfigurationApp, selectedConfigurationWeb, selectedPrice, ...apiConf } = conf
        const updatedConfiguration = await GQLConfiguration.Update.procedure(apiConf)
        if (!updatedConfiguration) ulog('e', 'OrdersAPIProdImpl::update', 'Configuration(U) failed', `ID=[${conf.fullpath}]`)
      }
    }

    // delete the asset folder
    if (metadata.configurationsToDelete.length) {
      for (const delConf of metadata.configurationsToDelete) {
        await GQLConfiguration.Delete.procedure(delConf.fullpath)
        await GQLAssetFolder.Delete.procedure(delConf.attachmentFolderId)
      }
    }

    const updatedOrder = await MutateOrder.Update.procedure({
      fullpath: order.fullpath!,
      values: {
        ...OrderExport(order),
        configurations: ConfigurationIDs.map(id => ({ id, type: 'object' })),
      },
    })

    return updatedOrder
  },

}


const createOrderFinish = async (keys: CreateIDs) => MutateOrder.Update.procedure({
  fullpath: keys.orderFullPath,
  values: {
    invoicingData: { id: keys.invoicing, type: 'object' },
    contactData: { id: keys.contact, type: 'object' },
    configurations: keys.configurations.map(item => ({ id: item, type: 'object' })),
  },
})

const createOrderRevert = (key: string[]) => {
  // TODO : STACI ABY TO BOL IBA PARENTKEY!
  clog(key)
  return undefined
}
