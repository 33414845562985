export const defaultGQLResponse = {
  data: null,
}

export const defaultGQLCollectionResponse = {
  data: [],
  totalPages: 0,
}

export const defaultGQLMutationResponse = {
  ...defaultGQLResponse,
  success: false,
  message: null,
}
