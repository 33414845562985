import { Nullable } from '@/common/custom/ts-customization'
import { mapFieldsFunction } from '@/common/custom/vuex-map-fields-customization'
import { RouterPushQuery, RTUnion } from '@/router/interface'
import { Module } from 'vuex'
import { getField, updateField, createHelpers } from 'vuex-map-fields'
import { RootState } from './root.store'

export type AppDataState = {

  /** if user navigates into page X, but auth module requests user to reauthenticate, store the route ID */
  cachedRoute: string
  cachedQuery: Nullable<RouterPushQuery>

}

export const appDataModule: Module<AppDataState, RootState> = {
  namespaced: true,

  state: {
    cachedRoute: '',
    cachedQuery: undefined,
  },

  getters: { getField },
  mutations: { updateField },

  actions: {

    cacheRedir(context, payload: { route: RTUnion, query: RouterPushQuery }) {
      // We don't want to store anything like this as the initial route
      switch (payload.route) {
        case 'index':                 return
        case 'prihlaseni':            return
        case 'admin':                 return
        case 'montaznik':             return
        case 'pobocka':               return
        case 'notfound':              return
        case 'zapomenute_heslo':      return
        case 'obnova_hesla':          return
        case 'potvrzeni_objednavky':  return
        case 'potvrzeni_nabidky':     return
      }
      context.commit('updateField', { path: 'cachedRoute', value: payload.route })
      context.commit('updateField', { path: 'cachedQuery', value: payload.query })
    },

  },
}

export const mapFieldsAppData = createHelpers({
  getterType: 'appData/getField',
  mutationType: 'appData/updateField',
}).mapFields as mapFieldsFunction<AppDataState>

/*
https://www.npmjs.com/package/vuex-map-fields
https://github.com/maoberlehner/vuex-map-fields/issues/75
https://github.com/maoberlehner/vuex-map-fields
...(mapFields('appData', { hmm: 'newOrder.internalNote' }) as { [key: string]: { get(): Order.Form; set(value: Order.Form): void } })
*/
