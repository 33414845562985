import type { GQLMutationResponseContext, GQLRawMutationResponse } from '@/api/graphql/_types'
import type { MaybeNull } from '@/common/_types'
import type { Order } from '@/models/order/Order'
import { apolloProvider } from '@/plugins/vue-apollo.plugin'
import mutation from './_mutation'
import { useAsyncState } from '@vueuse/core'
import { defaultGQLMutationResponse } from '@/api/graphql'
import type { CreateOfferWithNumberVariables } from '@/api/graphql/app/order/createOfferWithNumber/_types'

export const useCreateOfferWithNumber = () => {
  const _execute = async (variables: CreateOfferWithNumberVariables): Promise<GQLMutationResponseContext<MaybeNull<Order>>> => {
    const { output: data = null, success, message } = await apolloProvider.defaultClient
      .mutate<GQLRawMutationResponse<'createOfferWithNumber', { output: Order }>>({
        mutation,
        variables,
      })
      .then((result) => result.data!.createOfferWithNumber)

    return {
      data,
      success,
      message,
    }
  }

  return useAsyncState(_execute, defaultGQLMutationResponse, { immediate: false })
}
