import Vue from 'vue'
import Vuex, { ModuleTree } from 'vuex'
import VuexPersistence from 'vuex-persist'
import { appDataModule, AppDataState } from './appdata.store'
import { authModule, AuthState } from '@/store/auth.store'
import { getField, updateField, createHelpers } from 'vuex-map-fields'
import { mapFieldsFunction } from '@/common/custom/vuex-map-fields-customization'
import { OrderListTabKey } from '@/common/componentHelpers/OrdersView'
import { calendarModule, CalendarState } from './calendar.store'
import { modalModule, ModalState } from './modal.store'
import { clog } from '@/common/utils/AppConsole'
import { MaybeNull } from '@/common/_types'
import { useGetAppVersion } from '@/api/internal/getAppVersion'

Vue.use(Vuex)

export type RootState = {
  version: string
  timestamp: string

  /** Used in OrdersView. Currently available: 0 and 1 */
  tabListOrderIndex: number
  /** Used in OrdersView. Currently available: active, archived */
  tabListOrderKey: OrderListTabKey

  xdata: string // provisional data exchange field, used for assemblygroups

  transitionInProgress: boolean
  remoteVersion: MaybeNull<string>
}

export type CombinedStates =
  | RootState
  | AppDataState
  | AuthState
  | CalendarState
  | ModalState

/** Vuex Persist Plugin */
export const persistAuth = new VuexPersistence<RootState>({
  storage: window.localStorage,
  modules: ['auth'],
})


export type ModulesNamespaces = '' | 'auth' | 'appData' | 'calendar' | 'modal'
const moduleTree: ModuleTree<RootState> = {
  auth: authModule,
  appData: appDataModule,
  calendar: calendarModule,
  modal: modalModule,
}


const store = new Vuex.Store<RootState>({
  state: {
    version: '',
    timestamp: '',

    tabListOrderIndex: 0,
    tabListOrderKey: 'active',

    xdata: '',

    transitionInProgress: false,
    remoteVersion: null,
  },
  getters: {
    getField,
    isTransitionInProgress: (state) => state.transitionInProgress,
    appIsOutdated: (state) => (state.remoteVersion && state.version !== state.remoteVersion) ?? false,
  },
  mutations: {
    updateField,
    setTransitionInProgress(state, value: boolean) {
      state.transitionInProgress = value
    },
  },

  modules: moduleTree,

  actions: {
    async initialize(context) {
      context.commit('updateField', { path: 'version', value: process.env.VERSION })
      context.commit('updateField', { path: 'timestamp', value: process.env.VERSION_DATE })

      clog(`timestamp:${process.env.VERSION_DATE}; app_version:${process.env.VERSION};`)

      await context.dispatch('initializeAppVersionCheck')
    },
    async initializeAppVersionCheck(context) {
      const { execute: executeGetAppVersion, isLoading: isLoadingGetAppVersion } = useGetAppVersion()

      setInterval(async () => {
        if (isLoadingGetAppVersion.value) {
          return
        }

        const remoteVersion = await executeGetAppVersion()

        if (remoteVersion) {
          context.commit('updateField', { path: 'remoteVersion', value: remoteVersion.version })
        }
      }, 1000 * 60 * 10)
    },
    setTransitionState({ commit }, isInProgress: boolean) {
      commit('setTransitionInProgress', isInProgress)
    },
  },

  plugins: [persistAuth.plugin],
})



export const mapFieldsStore = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
}).mapFields as mapFieldsFunction<RootState>


export default store
