import Vue from 'vue'
import App from './App.vue'

// TODO removing service worker while in early development stage
// import './registerServiceWorker'

import router from './router/index.router'
import { withGuards } from './router/guard'
import '@/plugins/axios.plugin'

import store from './store/root.store'
import vuetify from '@/plugins/vuetify.plugin'
import { apolloProvider } from './plugins/vue-apollo.plugin'
import routePathProvider  from '@/plugins/routePath.plugin'
import DateFormatterPlugin  from '@/plugins/dateFormatter.plugin'
import authProvider  from '@/plugins/auth.plugin'
import i18n from '@/plugins/i18n.plugin'
import vueClickOutsideElement from 'vue-click-outside-element'
import VueCookies from 'vue-cookies'

import './styles/main.scss'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { appInit } from './init'

// Vue toasts
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

// Other
Vue.config.productionTip = false

Vue.use(VueToast, {
  duration: 6000,
  dismissible: true,
})
Vue.use(routePathProvider)
Vue.use(authProvider)
Vue.use(DateFormatterPlugin)
Vue.use(vueClickOutsideElement)
Vue.use(VueCookies, { expires: '60d' })

await appInit()

// Constructor
new Vue({
  router: withGuards(router),
  store,
  vuetify,
  apolloProvider,
  i18n,

  // beforeCreate() {},
  render: h => h(App),
}).$mount('#app')

// <script lang='tsx'>
// imports   ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
// component ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
