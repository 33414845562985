import axios from 'axios'
import { AppVersionResponse } from '@/api/internal/_types'
import { useAsyncState } from '@vueuse/core'
import { MaybeNull } from '@/common/_types'
import { appBaseUrl } from '@/common/helpers'

export const useGetAppVersion = () => {
  const _execute = async (): Promise<MaybeNull<AppVersionResponse>> => {
    const hash = Date.now()
    return await axios.get<AppVersionResponse>(`/version.json?${hash}`, { responseType: 'json', baseURL: appBaseUrl() })
      .then((result) => result.data)
  }

  return useAsyncState(_execute, null, { immediate: false })
}
