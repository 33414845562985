//
// Application initialization module
// This implementation is run on app init => always when refreshing page
//

import { API } from './api'
import { storeAction } from './store/store.actions'

export const appInit = async () => {
  await Promise.allSettled([
    // init root store
    API.auth.initialize(),
    // init auth store (performs localstorage search && API call)
    storeAction.initApp(),
  ])
}
