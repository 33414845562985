import gql from 'graphql-tag'

export default gql`
  mutation createOfferWithNumber($configuratorVersion: String) {
    createOfferWithNumber(configuratorVersion: $configuratorVersion) {
      message
      success
      output {
        id
        key
        fullpath
        orderNumber
        orderType
        configuratorVersion
        state
        assetFolderId
        __typename
      }
      __typename
    }
  }
`
